<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection @filterTable="searchTable"></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Notfound v-if="!searchItems"></Notfound>
      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>عدد حسابات المؤسسات:</span> <span>{{ table_length }}</span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <!-- Start Main Section -->
        <main class="table-print main-table">
          <!-- start of  header of printSection  -->
          <PrintHead
            :title="'حسابات المؤسسات'"
            :number="table_length"
          ></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb"
            :headers="headers"
            :items="rows"
            :search="search"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            :items-per-page="rows.length"
            item-key="id"
            hide-default-footer
          >
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <!-- ================== You Can use any slots you want ================== -->
            <!-- Select header field -->
            <template v-slot:[`header.desc`]="{ header }">
              {{ header.text + " ..." }}
            </template>
            <!-- revision status-->
            <template v-slot:[`item.review_status`]="{ item }">
              <v-chip color="#d7c100" v-if="item.review_status == 0">
                منتظر
              </v-chip>
              <v-chip color="green" v-else> تأكيد </v-chip>
            </template>
            <!-- Confirm revision-->
            <template v-slot:[`item.revision_done`]="{ item }">
              <!--  -->
              <div class="_actions actions2">
                <button
                  type="button"
                  class="accept confirm_btn ml-0"
                  @click="revisionDone(item)"
                  v-if="item.review_status == 0"
                >
                  <i class="far fa-check"></i>
                </button>
                <span v-else> ---- </span>
              </div>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span class="redColor" v-if="item.amount < 0">
                {{ Number(item.amount).toLocaleString() }}
              </span>
              <span class="greenColor" v-else-if="item.amount > 0">
                {{ Number(item.amount).toLocaleString() }}
              </span>
              <span v-else> {{ Number(item.amount).toLocaleString() }} </span>
            </template>
            <!-- ====== Select row field ====== -->
            <!-- title -->
            <template v-slot:[`item.title`]="{ item }">
              {{ item.title }}
            </template>

            <!-- descreption -->
            <template v-slot:[`item.desc`]="{ item }">
              {{ item.desc.slice(0, 40) + "..." }}
            </template>

            <!-- avatar -->
            <template v-slot:[`item.avatar`]="{ item }">
              <img @click="show_model_1" class="image" :src="item.avatar" />
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-chip color="red" v-if="item.active == 0"> غير مفعل </v-chip>
              <v-chip color="green" v-if="item.active == 1"> مفعل </v-chip>
            </template>
            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>
            <!-- Confirm-->
            <template v-slot:[`item.pdf_file`]="{ item }">
              <button
                type="button"
                @click="generateReport(item)"
                style="font-size: 20px"
              >
                <i class="fal fa-download"></i>
              </button>
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <v-icon class="show" small @click="showItem(item)">
                  fal fa-eye
                </v-icon>
                <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon>
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- confirm dialog -->
              <v-dialog v-model="dialogConfirm" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    هل تمت المراجعه علي العملية ؟
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="revisionConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogConfirm = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Row -->
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </div>
    <!-- pdf file  -->
    <section class="pdf-print">
      <div class="pdf-file">
        <div class="text">
          <h3>بيانات مؤسسة</h3>
          <div class="sub">
            <div class="items">
              <p>إسم المؤسسة</p>
              <p>{{ pdfContent.enterpriceName }}</p>
            </div>
            <div class="items">
              <p>إسم صاحب المؤسسة</p>
              <p>{{ pdfContent.enterpriceUser }}</p>
            </div>
            <div class="items">
              <p>إسم البنك</p>
              <p>{{ pdfContent.bankName }}</p>
            </div>
            <div class="items">
              <p>رقم الحساب</p>
              <p>{{ pdfContent.bankAccount }}</p>
            </div>
            <div class="items">
              <p>حالة الحساب</p>
              <p>{{ pdfContent.accountStatus }}</p>
            </div>
            <div class="items">
              <p>رصيد حساب المؤسسة</p>
              <p>{{ pdfContent.enterpriceAmount }}</p>
            </div>
            <div class="items">
              <p>هل تم تقديم طلب لفتح حساب ؟</p>
              <p>{{ pdfContent.question1 }}</p>
            </div>
            <div class="items">
              <p>هل تم تقديم طلب لفتح أون لاين ؟</p>
              <p>{{ pdfContent.question2 }}</p>
            </div>
            <div class="items">
              <p>هل تم تقديم طلب إضافة صرافه ؟</p>
              <p>{{ pdfContent.question3 }}</p>
            </div>
            <div class="items">
              <p>هل تم تسليم الصرافة للمندوب ؟</p>
              <p>{{ pdfContent.question4 }}</p>
            </div>
            <div class="items">
              <p>عدد الصرافات</p>
              <p>{{ pdfContent.sarafNum }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/enterpriceAccount";
export default {
  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "حسابات المؤسسات",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "all",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      dialogConfirm: false,
      itemtoConfirm: null,
      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,
      // pdf
      pdfContent: {
        enterpriceName: null,
        enterpriceUser: null,
        bankName: null,
        bankAccount: null,
        swiftNum: null,
        accountStatus: null,
        enterpriceAmount: null,
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        sarafNum: null,
      },
      // excel data
      excelData: {
        name: "حسابات المؤسسات",
        items: null,
        json_fields: {
          "اسم المؤسسة": "enterprise_name",
          "اسم البنك": "bank_name",
          "A/C NO": "bank_account_number",
          "حالة الحساب": "active",
          "رصيد الحساب": {
            field: "amount",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          "عدد الصرافات": "atm_count",
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        var userType = JSON.parse(this.$store.state.usertype) || null;
        if (
          userType.includes("superadmin") ||
          (userType.includes("enterprise") &&
            userType.includes("confirm_enterprise"))
        ) {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "اسم المؤسسة",
              value: "enterprise_name",
              align: "center",
              width: 180,
            },
            {
              text: "اسم البنك",
              value: "bank_name",
              align: "center",
              width: 180,
            },
            {
              text: "A/C NO",
              value: "bank_account_number",
              align: "center",
              width: 150,
              sortable: false,
            },
            {
              text: "حالة الحساب",
              value: "active",
              align: "center",
              width: 100,
              sortable: false,
            },
            {
              text: "رصيد الحساب",
              value: "amount",
              align: "center",
              width: 100,
              sortable: true,
            },
            {
              text: "عدد الصرافات",
              value: "atm_count",
              align: "center",

              width: 100,
              sortable: false,
            },
            {
              text: "ملف pdf",
              value: "pdf_file",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 80,
              sortable: false,
            },
            {
              text: "المراجعة",
              value: "review_status",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "تأكيد",
              value: "revision_done",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "التحكم",
              value: "actions",
              sortable: false,
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 100,
            },
          ];
        } else if (!userType.includes("confirm_enterprise")) {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "اسم المؤسسة",
              value: "enterprise_name",
              align: "center",
              width: 180,
            },
            {
              text: "اسم البنك",
              value: "bank_name",
              align: "center",
              width: 180,
            },
            {
              text: "A/C NO",
              value: "bank_account_number",
              align: "center",
              width: 150,
              sortable: false,
            },
            {
              text: "حالة الحساب",
              value: "active",
              align: "center",
              width: 100,
              sortable: false,
            },
            {
              text: "رصيد الحساب",
              value: "amount",
              align: "center",
              width: 100,
              sortable: true,
            },
            {
              text: "عدد الصرافات",
              value: "atm_count",
              align: "center",

              width: 100,
              sortable: false,
            },
            {
              text: "ملف pdf",
              value: "pdf_file",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 80,
              sortable: false,
            },
            {
              text: "المراجعة",
              value: "review_status",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "التحكم",
              value: "actions",
              sortable: false,
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 100,
            },
          ];
        } else {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "اسم المؤسسة",
              value: "enterprise_name",
              align: "center",
              width: 180,
            },
            {
              text: "اسم البنك",
              value: "bank_name",
              align: "center",
              width: 180,
            },
            {
              text: "A/C NO",
              value: "bank_account_number",
              align: "center",
              width: 150,
              sortable: false,
            },
            {
              text: "حالة الحساب",
              value: "active",
              align: "center",
              width: 100,
              sortable: false,
            },
            {
              text: "رصيد الحساب",
              value: "amount",
              align: "center",
              width: 100,
              sortable: true,
            },
            {
              text: "عدد الصرافات",
              value: "atm_count",
              align: "center",

              width: 100,
              sortable: false,
            },
            {
              text: "ملف pdf",
              value: "pdf_file",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 80,
              sortable: false,
            },
            {
              text: "المراجعة",
              value: "review_status",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "تأكيد",
              value: "revision_done",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
          ];
        }
      }
    },
  },

  methods: {
    // generate pdf
    generateReport(companyData) {
      document.title = companyData.enterprise_name;
      this.pdfContent.enterpriceName = companyData.enterprise_name;
      this.pdfContent.enterpriceUser = companyData.owner_name;
      this.pdfContent.bankName = companyData.bank_name;
      this.pdfContent.bankAccount = companyData.bank_account_number;
      if (companyData.active == 1) {
        this.pdfContent.accountStatus = "مفعل";
      } else {
        this.pdfContent.accountStatus = "غير مفعل";
      }
      this.pdfContent.enterpriceAmount = Number(
        companyData.amount
      ).toLocaleString();
      if (companyData.is_account_opened.status == 1) {
        this.pdfContent.question1 = "نعم";
      } else {
        this.pdfContent.question1 = "لا";
      }
      if (companyData.is_online_request.status == 1) {
        this.pdfContent.question2 = "نعم";
      } else {
        this.pdfContent.question2 = "لا";
      }
      if (companyData.is_request_atm.status == 1) {
        this.pdfContent.question3 = "نعم";
      } else {
        this.pdfContent.question3 = "لا";
      }
      if (companyData.is_atm_delivered.status == 1) {
        this.pdfContent.question4 = "نعم";
      } else {
        this.pdfContent.question4 = "لا";
      }
      this.pdfContent.sarafNum = companyData.atm_count;
      setTimeout(() => {
        var iframePrint = document.querySelector(".iframePrint");
        var iframeHead = document.head.innerHTML;
        var printContents = document.querySelector(".pdf-print").innerHTML;
        iframePrint.contentWindow.document.write(iframeHead + printContents);
        setTimeout(() => {
          window.frames["printf"].print();
          iframePrint.contentWindow.document.close();
          document.title = "KHP-Group";
        }, 500);
      }, 500);
    },
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/enterpriceAccount/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/enterpriceAccount/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/enterpriceAccount/edit/" + item.id });
    },
    // confirm item
    revisionDone(item) {
      this.dialogConfirm = true;
      this.itemtoConfirm = item;
    },
    revisionConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "POST",
        url: `client/account/review/${this.itemtoConfirm.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogConfirm = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم التأكيد بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogConfirm = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/account/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ==================== End CRUD ====================

    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/account",
        params: {
          enterprise: this.searchItems ? this.searchItems.enterprice : "",
          bank: this.searchItems ? this.searchItems.bank : "",
        },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("enterprise") &&
      !userType.includes("confirm_enterprise")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>

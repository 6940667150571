import axios from "axios";
import store from "../../../store/index";

export default {
    // ============ Logout
    logOut(context) {
        axios({
                method: "post",
                url: "logout",
                headers: {
                    "cache-control": "no-cache",
                    "content-type": "application/json",
                    Accept: "application/json",
                    "Accept-language": store.getters["lang_module/lang"], // ==> Store (Vuex) <==
                },
            })
            .then(() => {})
            .catch((err) => {
                this.btn_loaidng = false;
                this.$iziToast.error({
                    title: this.$t("validation.error"),
                    message: err.response.data.message,
                });
            });

        context.commit("remove_token");
        context.commit("remove_userType");
        context.commit("remove_userId");
        context.commit("remove_userImage");
        context.commit("remove_userPhone");
        context.commit("remove_fullName");
        context.commit("remove_userCode");
        location.reload();
    },

    // ============ Login
    logIn(context, payload) {
        axios
            .get("refresh_token", {
                headers: {
                    Authorization: "bearer" + payload.token,
                },
            })
            .then((res) => {
                context.commit("set_token", res.data.data);
                context.commit("set_usertype", payload.userType);
                context.commit("set_userId", payload.userId);
                context.commit("set_userImage", payload.userImage);
                context.commit("set_userPhone", payload.userPhone);
                context.commit("set_fullName", payload.fullName);
                context.commit("set_userCode", payload.userCode);
                localStorage.setItem("Rehab_App_Token", res.data.data);
                store.commit("auth_module/set_token", res.data.data);
                axios.defaults.headers.common["Authorization"] = "bearer" + res.data.data;
                store.dispatch("get_notification");
                location.reload();
            });
    },
};
import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
    namespaced: true,
    state() {
        return {
            token: localStorage.getItem("Rehab_App_Token"),
            usertype: localStorage.getItem("Rehab_App_User_Type"),
            user_id: localStorage.getItem("Rehab_App_User_Id"),
            userImage: localStorage.getItem("Rehab_App_userImg"),
            userPhone: localStorage.getItem("Rehab_App_userPhone"),
            userName: localStorage.getItem("Rehab_App_userPhone"),
            fullName: localStorage.getItem("Rehab_App_fullName"),
            userCode: localStorage.getItem("Rehab_App_userCode"),
        };
    },
    mutations,
    actions,
    getters,
};
<template>
  <div>
    <!-- Start Breadcrumb -->
    <Breadcrumb
      :items="items"
      @openFilter="$store.commit('openFilter')"
      :show_print="false"
      :show_filter="false"
    />
    <!-- End Breadcrumb -->

    <!-- Start Statistics Card-->
    <div class="row mb-5">
      <div class="col-lg-3 col-md-4 col-12">
        <stats-card
          :icon="'far fa-file-invoice-dollar'"
          :title="'كل الفواتير'"
          :number="rows.length"
          :color="current_theme == 'dark' ? '#282c87' : '#acc4d9'"
          :bgColor="'transparent'"
        ></stats-card>
      </div>
    </div>
    <!-- End Statistics Card-->

    <!-- Start Main Section -->
    <main class="main-table">
      <v-data-table
        class="thumb"
        :headers="headers"
        :items="rows"
        :search="search"
        :custom-filter="filterSearch"
        :loading="lodaing"
        :loading-text="$t('table.loadingData')"
        v-model="selected"
        :single-select="singleSelect"
        item-key="id"
        :items-per-page="rows.length"
        hide-default-footer
      >
        <!-- ================== You Can use any slots you want ================== -->
        <!-- ====== Select row field ====== -->
        <!-- title -->
        <template v-slot:[`item.title`]="{ item }">
          <v-chip color="red">
            {{ item.title }}
          </v-chip>
        </template>

        <!-- Select no data State -->
        <template v-slot:no-data>
          {{ $t("table.noData") }}
        </template>
        <template v-slot:[`item.index`]="{ index }">
          {{ index + 1 }}
        </template>
        <!-- Select actions-->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="_actions">
            <!-- <v-icon class="show" small @click="showItem(item)">
              fal fa-eye
            </v-icon> -->
            <!-- <v-icon class="edit" small @click="editItem(item)">
                fal fa-edit
              </v-icon> -->
            <v-icon class="delete" small @click="deleteItem(item)">
              fal fa-trash
            </v-icon>
          </div>
        </template>

        <!-- ======================== Start Top Section ======================== -->
        <template v-slot:top>
          <!-- Delete dialog -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 justify-center">{{
                $t("table.deletedialog.areYouSure")
              }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#1B5E20" @click="deleteItemConfirm"
                  >{{ $t("table.deletedialog.ok") }}
                  <span class="btn_loader_model" v-if="loadingBtnDialog"></span
                ></v-btn>
                <v-btn color="#F44336" @click="dialogDelete = false">{{
                  $t("table.deletedialog.cancel")
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <!-- ======================== End Top Section ======================== -->
      </v-data-table>
    </main>
    <!-- End Main Section -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";

export default {
  name: "AllReceipts",

  components: {
    StatsCard,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "كل الفواتير",
          disabled: true,
          href: "",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Your Data
      rows: [], // injected in created

      // ========== Loading
      lodaing: false,
    };
  },

  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "إسم العميل",
            value: "client_name",
            sortable: false,
            align: "center",
          },
          {
            text: "إسم المؤسسة",
            value: "organization_name",
            sortable: false,
            align: "center",
          },
          {
            text: "إسم الشركة",
            value: "company_name",
            sortable: false,
            align: "center",
          },
          {
            text: "تاريخ إصدار الفاتورة",
            value: "created_at",
            sortable: false,
            align: "center",
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
  },

  methods: {
    // ===== Search Method =====
    filterClick(word) {
      if (!this.lodaing) {
        if (word != "all") {
          this.search = word;
        } else {
          this.search = "";
        }
        this.filterSearch();
      }
    },

    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/deliverables/add" });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({ path: "/deliverables/edit/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/delivery/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // ================ Set Rows
    setRows() {
      this.lodaing = false;
      this.rows = [
        {
          id: 1,
          client_name: "Client Name 1",
          organization_name: "Organization Name 1",
          company_name: "Company Name 1",
          created_at: "29-3-2022",
        },
        {
          id: 2,
          client_name: "Client Name 2",
          organization_name: "Organization Name 2",
          company_name: "Company Name 2",
          created_at: "29-3-2022",
        },
      ];
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<template>
  <nav class="main_nav" :class="{ smallScreen: isSmallScreen }">
    <div class="custom_card">
      <div class="navContent">
        <div class="user">
          <div class="text">
            <span class="name">أهلا بك {{ userName }} </span>
          </div>
        </div>

        <div class="otherSide">
          <ul class="routerIcons">
            <li class="time">
              <span> {{ currentTime }} </span>
              {{ currentDate }}
            </li>
            <li @click="logOut">
              <i class="fal fa-sign-out"></i>
            </li>
            <li
              class="notification_list_item"
              @click.stop="toggle_notification_menu"
            >
              <div class="notification_icon">
                <i class="far fa-bell"></i>
                <span
                  v-if="
                    $store.state.unreadnotifications_count > 0 &&
                    $store.state.unreadnotifications_count < 20
                  "
                  class="notificationNumber"
                  >{{ $store.state.unreadnotifications_count }}</span
                >
                <span
                  v-else-if="$store.state.unreadnotifications_count >= 20"
                  class="notificationNumber"
                  >+20</span
                >
              </div>

              <!-- notification dropdown -->
              <transition name="fadeUpTransform">
                <div
                  v-if="notification_menu"
                  class="custom_card notification_dropdown nav_dropdown"
                >
                  <!-- START:: EMPTY NOTIFICATIONS -->
                  <div
                    class="empty_notifications_wrapper d-flex flex-column justify-content-center align-items-center py-4"
                    v-if="$store.state.notification.length == 0"
                  >
                    <img
                      src="../../assets/images/no_notifications.svg"
                      alt="No Notifications"
                      width="120"
                      height="150"
                    />

                    <h4>لا يوجد اشعارات</h4>
                  </div>
                  <!-- END:: EMPTY NOTIFICATIONS -->

                  <ul class="notifications_container" v-else>
                    <li
                      v-for="item in notifications"
                      :key="item.id"
                      :class="{ unread: item.read_at == null }"
                    >
                      <router-link
                        :to="{ path: item.routeLink || '/' }"
                        class="item"
                      >
                        <span class="icon"><i class="fal fa-bell"></i></span>
                        <div class="text">
                          <h4>{{ item.title }}</h4>
                          <span class="time">{{ item.created_at }}</span>
                        </div>
                      </router-link>
                      <div class="dotIcon" v-if="item.read_at == null"></div>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>

            <li
              v-if="current_theme == 'dark' && !isSmallScreen"
              @click="changeTheme('light')"
            >
              <i class="fal fa-sun"></i>
            </li>
            <li
              v-if="current_theme == 'light' && !isSmallScreen"
              @click="changeTheme('dark')"
            >
              <i class="fal fa-moon"></i>
            </li>

            <!-- <li v-if="lang == 'ar' && !isSmallScreen" @click="changeLang('en')">
              <i class="fal fa-globe"></i>
            </li>
            <li v-if="lang == 'en' && !isSmallScreen" @click="changeLang('ar')">
              <i class="fal fa-globe"></i>
            </li> -->
          </ul>

          <span @click="toggleMenu" v-if="isSmallScreen" class="toggleMenue">
            <i class="fas fa-bars"></i>
          </span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// import {mapGetters} from "vuex"
export default {
  data() {
    return {
      profile_menu: false,
      notification_menu: false,
      data: {},
      currentDate: null,
      currentTime: null,
      userName: localStorage.getItem("Rehab_App_fullName"),
      notifications: [],
    };
  },

  methods: {
    setRoute() {
      const all_types = [
        {
          value: "lock",
          route: "/locks",
        },
        {
          value: "lock_delete",
          route: "/locks/trash",
        },
        {
          value: "transfare_amount",
          route: "/locks/transfer-balances",
        },
        {
          value: "transfare_amount_delete",
          route: "/locks/transfer-balances/trash",
        },
        {
          value: "customer",
          route: "/clients",
        },
        {
          value: "customer_delete",
          route: "/clients/trash",
        },
        {
          value: "client_account",
          route: "/clientCommission",
        },
        {
          value: "client_account_delete",
          route: `/client_accounts/trash`,
        },
        {
          value: "deposit_operation",
          route: "/deposits_ultimate",
        },
        {
          value: "deposit_operation_delete",
          route: "/deposits_ultimate/trash",
        },
        {
          value: "withdraw_operation",
          route: "/withdraws_ultimate",
        },
        {
          value: "withdraw_operation_delete",
          route: "/withdraws_ultimate/trash",
        },
        {
          value: "banks",
          route: "/banks",
        },
        {
          value: "banks_delete",
          route: "/banks/trash",
        },
        {
          value: "bank_deposit",
          route: "/bank_operations/deposits",
        },
        {
          value: "bank_deposit_delete",
          route: "/bank_operations/deposits/trash",
        },
        {
          value: "bank_withdraw",
          route: "/bank_operations/withdraws",
        },
        {
          value: "bank_withdraw_delete",
          route: "/bank_operations/withdraws/trash",
        },
        {
          value: "dubai_banks",
          route: "/banksDubai",
        },
        {
          value: "dubai_banks_delete",
          route: "/banksDubai/trash",
        },
        {
          value: "dubai_account_banks",
          route: "/banksDubai/account/",
        },
        {
          value: "dubai_account_banks_delete",
          route: `/banksDubai/accountTrash`,
        },
        {
          value: "dubai_banks_deposits",
          route: "/banksDubai/amount",
        },
        {
          value: "dubai_banks_deposits_delete",
          route: "/banksDubai/amount/trash",
        },
        {
          value: "dubai_banks_withdraw",
          route: "/banksDubai/withdraw",
        },
        {
          value: "dubai_banks_withdraw_delete",
          route: "/banksDubai/withdraw/trash",
        },
        {
          value: "currency",
          route: "/currency",
        },
        {
          value: "currency_delete",
          route: "/currency/trash",
        },
        {
          value: "currency_buy",
          route: "/currency/buy",
        },
        {
          value: "currency_buy_delete",
          route: "/currency/by-currency-trash",
        },
        {
          value: "currency_sale",
          route: "/currency/sale",
        },
        {
          value: "currency_sale_delete",
          route: "/currency/sale-currency-trash",
        },
        {
          value: "currency_transfare",
          route: "/currency/transfer",
        },
        {
          value: "currency_transfare_delete",
          route: "/currency/transfer-currency-trash",
        },
        {
          value: "service_deposit",
          route: "/service_operations/deposit",
        },
        {
          value: "service_deposit_delete",
          route: "/service_operations/deposit/trash",
        },
        {
          value: "service_widthdraw",
          route: "/service_operations/withdraw",
        },
        {
          value: "service_widthdraw_delete",
          route: "/service_operations/withdraw/trash",
        },
        {
          value: "expence",
          route: "/expenses",
        },
        {
          value: "expence_delete",
          route: "/expenses/trash",
        },
        {
          value: "enterprise_account",
          route: "/enterpriceOwners",
        },
        {
          value: "enterprise_account_delete",
          route: "/enterpriceOwners/trash",
        },
        {
          value: "enterprise",
          route: "/enterprices",
        },
        {
          value: "enterprise_delete",
          route: "/enterprices/trash",
        },
        {
          value: "enterprise_amount",
          route: "/enterpriceAccount",
        },
        {
          value: "enterprise_amount_delete",
          route: "/enterpriceAccount/trash",
        },
        {
          value: "categories",
          route: "/categories",
        },
        {
          value: "categories_delete",
          route: "/categories/trash",
        },
        {
          value: "enterprise_reset",
          route: "/enterpriceCredits",
        },
        {
          value: "enterprise_reset_confirm",
          route: "/enterpriceCredits",
        },
        {
          value: "enterprise_reset_delete",
          route: "/enterpriceCredits/trash",
        },
        {
          value: "atm",
          route: "/enterpriceATM",
        },
        {
          value: "atm_confirm",
          route: "/enterpriceATM",
        },
        {
          value: "atm_delete",
          route: "/enterpriceATM/trash",
        },
        {
          value: "companies",
          route: "/allCompanies",
        },
        {
          value: "companies_confirm",
          route: "/allCompanies",
        },
        {
          value: "companies_refused",
          route: "/allCompanies",
        },
        {
          value: "companies_delete",
          route: "/companies/trash",
        },
        {
          value: "products",
          route: "/products",
        },
        {
          value: "products_delete",
          route: "/products/trash",
        },
        {
          value: "company_transfares",
          route: "/companyTransfers",
        },
        {
          value: "company_actions",
          route: "/enterpriceActions",
        },
        {
          value: "company_work_refused",
          route: "/enterpriceActions",
        },
        {
          value: "company_transfares_delete",
          route: "/CompanyAction/trash",
        },
        {
          value: "company_actions_confirm",
          route: "/enterpriceActions",
        },
        {
          value: "company_actions_delete",
          route: "/enterpriceActions/trash",
        },
        {
          value: "company_cach",
          route: "/cach",
        },
        {
          value: "company_cach_confirm",
          route: "/cach",
        },
        {
          value: "company_cach_delete",
          route: "/cach/trash",
        },
        {
          value: "branchs",
          route: "/allBranches",
        },
        {
          value: "branchs_delete",
          route: "/branches/trash",
        },
        {
          value: "employees",
          route: "/employees",
        },
        {
          value: "employees_delete",
          route: "/employees/trash",
        },
        {
          value: "country",
          route: "/countries",
        },
        {
          value: "country_delete",
          route: "/countries/trash",
        },
      ];
      this.$store.state.notification.forEach((serverEle) => {
        all_types.forEach((ele) => {
          if (ele.value == serverEle.notify_type) {
            if (ele.value == "client_account") {
              serverEle["routeLink"] = "/clientCommission/" + serverEle.body;
            } else if (ele.value == "dubai_account_banks") {
              serverEle["routeLink"] = "/banksDubai/account/" + serverEle.body;
            } else {
              serverEle["routeLink"] = ele.route;
            }
          }
        });
      });
      this.notifications = this.$store.state.notification;
    },

    toggle_profile_menu() {
      this.profile_menu = !this.profile_menu;
      this.notification_menu = false;
    },

    toggle_notification_menu() {
      this.notification_menu = !this.notification_menu;
      this.$axios.get("/read/notifications").then(() => {
        this.$store.dispatch("get_notification");
      });
      this.setRoute();
      this.profile_menu = false;
    },

    toggleMenu() {
      document.querySelector(".smallScreenSidebar").classList.toggle("active");
    },

    logOut() {
      this.$store.dispatch("auth_module/logOut");
    },

    changeTheme(value) {
      this.$store.dispatch("theme_module/changeTheme", value);
      if (value == "light") {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
    },

    changeLang(value) {
      this.$store.dispatch("lang_module/switchLang", value);
    },
    getCurrentTime() {
      var date = new Date();
      var months = [
        "يناير",
        "فبراير",
        "مارس",
        "إبريل",
        "مايو",
        "يونيو",
        "يوليو",
        "أغسطس",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر",
      ];
      var days = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ];
      var time = new Date().toLocaleTimeString();
      this.currentDate =
        days[date.getDay()] +
        " " +
        date.getDate() +
        "," +
        months[date.getMonth()] +
        "," +
        date.getFullYear();
      this.currentTime = time;
    },
    refreshToken() {
      if (this.$store.getters["auth_module/token"]) {
        this.$axios
          .get("refresh_token", {
            headers: {
              Authorization:
                "bearer" + localStorage.getItem("Rehab_App_Token"),
            },
          })
          .then((res) => {
            localStorage.setItem("Rehab_App_Token", res.data.data);
            this.$store.commit("auth_module/set_token", res.data.data);
            this.$axios.defaults.headers.common["Authorization"] =
              "bearer" + res.data.data;
            this.$store.dispatch("get_notification");
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.$iziToast.error({
                timeout: 2000,
                message: "تسجيل خروج من السيستم",
                position: "topRight",
              });
              setTimeout(() => {
                this.$store.dispatch("auth_module/logOut");
              }, 500);
            }
          });
      }
    },
    check_token() {
      if (this.$store.getters["auth_module/token"]) {
        this.$axios
          .get("check_token", {
            headers: {
              Authorization:
                "bearer" + localStorage.getItem("Rehab_App_Token"),
            },
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.$iziToast.error({
                timeout: 2000,
                message: "تسجيل خروج من السيستم",
                position: "topRight",
              });
              setTimeout(() => {
                this.$store.dispatch("auth_module/logOut");
              }, 500);
            }
          });
      }
    },
  },

  computed: {
    isSmallScreen() {
      return this.$store.getters["sideNav_module/isSmallScreen"];
    },

    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },

    lang() {
      return this.$store.getters["lang_module/lang"];
    },
  },

  mounted() {
    setInterval(() => {
      this.getCurrentTime();
    }, 1000);
    window.addEventListener("focus", () => {
      this.check_token();
    });
    setInterval(() => {
      alert('تسجيل خروج من السيستم')
      this.refreshToken();
    }, 7200000);
    document.querySelector("body").addEventListener("click", () => {
      this.profile_menu = false;
      this.notification_menu = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.notifications_container {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>


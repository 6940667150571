<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        @openFilter="$store.commit('openFilter')"
        :show_print="true"
        :show_filter="true"
      />
      <!-- End Breadcrumb -->

      <!-- Start Statistics Card-->
      <div class="row mb-5">
        <div
          class="col-lg-3 col-md-4 col-12"
          v-for="item in statisticsItems"
          :key="item.id"
        >
          <div class="wrraper">
            <stats-card
              :icon="'fal fa-funnel-dollar'"
              :title="item.title"
              :number="table_length"
              :color="current_theme == 'dark' ? '#282c87' : '#acc4d9'"
              :bgColor="'transparent'"
            ></stats-card>
          </div>
        </div>
      </div>
      <!-- End Statistics Card-->

      <!-- Start Main Section -->
      <main class="main-table">
        <v-data-table
          class="thumb"
          :headers="headers"
          :items="rows"
          :loading="lodaing"
          :loading-text="$t('table.loadingData')"
          v-model="selected"
          :single-select="singleSelect"
          item-key="id"
          :items-per-page="rows.length"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- ====== Select row field ====== -->
          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <v-icon class="edit" small @click="editItem(item)">
                fal fa-edit
              </v-icon>
              <v-icon class="delete" small @click="deleteItem(item)">
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <template v-slot:[`item.desc`]="{ item }">
            <div class="_actions">
              <v-icon
                class="show"
                small
                @click="
                  modal.descriptionModalIsOpen = true;
                  setItemDesc(item.desc);
                "
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>

          <template v-slot:[`item.notes`]="{ item }">
            <div class="_actions">
              <span v-if="item.notes == 'null' || item.notes == null">
                ----
              </span>
              <v-icon
                class="show"
                small
                v-else
                @click="
                  modal.notesModalIsOpen = true;
                  setItemNotes(item.notes);
                "
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.files`]="{ item }">
            <div class="_actions">
              <a :href="item.files" target="_blank">
                <v-icon class="show" small> fal fa-eye </v-icon>
              </a>
            </div>
          </template>
          <template v-slot:[`item.changeStatus`]>
            <div class="_actions">
              <button type="button" class="recive_btn">تسلم</button>
            </div>
          </template>
          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <!-- Delete dialog -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">{{
                  $t("table.deletedialog.areYouSure")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteItemConfirm"
                    >{{ $t("table.deletedialog.ok") }}
                    <span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span
                  ></v-btn>
                  <v-btn color="#F44336" @click="dialogDelete = false">{{
                    $t("table.deletedialog.cancel")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Add & Delete -->
            <v-row>
              <v-col cols="12" sm="4"> </v-col>
            </v-row>

            <!-- Description Modal -->
            <DescModal
              :desc="selectedItemDesc"
              :handelModalApperance="modal.descriptionModalIsOpen"
              @close="closeDescModal"
            />

            <NotesModal
              :desc="selectedItemNotes"
              :handelModalApperance="modal.notesModalIsOpen"
              @close="closeNotesModal"
            />
            <!-- Add & Delete -->
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>
      <!-- End Main Section -->

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->

      <!-- Start Filter Menu -->
      <table-filter
        :show="show_filter"
        @submit_filter="filterRows"
        @closeFilter="closeFilter"
      ></table-filter>
      <!-- End Filter Menu -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import DescModal from "@/components/modals/DescriptionModal.vue";
import NotesModal from "@/components/modals/NotesModal.vue";

export default {
  components: {
    StatsCard,
    DescModal,
    NotesModal,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.debits.title"),
          disabled: false,
          href: "/sliders",
        },
        {
          text: this.$t("breadcrumb.debits.all"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "كل المديونات",
          number: "10",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Filter
      show_filter: false,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // Custom Modal Data
      modal: {
        descriptionModalIsOpen: false,
        notesModalIsOpen: false,
      },
      selectedItemDesc: null,
      selectedItemNotes: null,

      // ========== Loding
      lodaing: false,
      table_length: null,
    };
  },

  computed: {
    // current theme
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      return [
        {
          text: "#",
          value: "index",
          sortable: false,
          align: "center",
          width: 10,
        },
        { text: "اسم العميل", value: "username", align: "center" },
        {
          text: "المبلغ",
          value: "amount",
          align: "center",
        },
        {
          text: "نوع المديونية",
          value: "type",
          align: "center",
        },
        {
          text: "تاريخ استحقاق الدين",
          value: "debit_date",
          align: "center",
        },
        {
          text: "العملة",
          value: "currency.name",
          align: "center",
        },
        {
          text: "الخزنة",
          value: "safe.name",
          align: "center",
        },
        {
          text: "التاريخ",
          value: "date",
          align: "center",
        },
        {
          text: "وصف",
          value: "desc",
          align: "center",
        },
        {
          text: "ملاحظات",
          value: "notes",
          align: "center",
        },
        {
          text: "المرفقات",
          value: "files",
          align: "center",
        },
        {
          text: "تسليم",
          value: "changeStatus",
          align: "center",
        },
        {
          text: "الحالة",
          value: "receive",
          align: "center",
        },
        {
          text: "التحكم",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  methods: {
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    //Show Filter
    openFilter() {
      this.show_filter = true;
    },
    closeFilter() {
      this.show_filter = false;
    },

    // Filter
    filterRows(obj) {
      console.log(obj);
    },

    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    setItemNotes(notes) {
      this.selectedItemNotes = notes;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    closeNotesModal() {
      this.modal.notesModalIsOpen = false;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/debits/add" });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({ path: "/debits/edit/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/debt/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },

    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/debt",
      })
        .then((res) => {
          this.rows = res.data.data.map((item) => {
            return {
              id: item.id,
              username: item.customer.name,
              amount: item.amount,
              type: item.type,
              safe: { id: item.wallet.id, name: item.wallet.name },
              receive: "سلم أم لم يسلم",
              currency: { id: item.currency.id, name: item.currency.name_ar },
              date: item.date,
              debit_date: item.expire_date,
              desc: item.desc,
              notes: item.notes,
              files: item.image,
            };
          });
          this.table_length = this.rows.length;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="login">
    <div class="login_box">
      <div class="inputs custom_card" v-if="levelCode == 'email'">
        <div class="card-header">
          <div class="log-img">
            <img src="@/assets/images/icons/logo-white.png" />
          </div>
          <h4>تغيير كلمة المرور</h4>
          <p>
            يرجي تسجيل البريد الإلكتروني الخاص بك و سيصلك كود خلال Al-Rehab
            Token App
          </p>
        </div>
        <form class="form form-horizontal" @submit.prevent="validateLoginForm">
          <div class="form-body">
            <div class="row">
              <div class="col-12">
                <!-- START:: EMAIL INPUT -->
                <div class="input_wrapper animated_placeholder">
                  <input
                    type="email"
                    class="form-control"
                    id="login_email"
                    v-model.trim="email"
                    @input="checkIfInputIsEmpty"
                    autocomplete="off"
                    autofill="off"
                    value=""
                  />
                  <label for="login_email" class="form-label"
                    >البريد الإلكتروني</label
                  >
                </div>
                <!-- END:: EMAIL INPUT -->
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <!-- START:: BUTTON -->
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              متابعة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
            <!-- END:: BUTTON -->
          </div>
        </form>
      </div>
      <!-- ------------------------------===================================-------------------------- -->
      <div class="inputs custom_card" v-if="levelCode == 'code'">
        <div class="card-header">
          <div class="log-img">
            <img src="@/assets/images/icons/logo-white.png" />
          </div>
          <h4>تغيير كلمة المرور</h4>
          <p>يرجي إدخال الكود المرسل علي Al-Rehab Token App</p>
        </div>
        <form
          class="form form-horizontal"
          dir="ltr"
          @submit.prevent="validateCodeForm"
        >
          <div class="form-body">
            <div class="row">
              <div class="col-12">
                <!-- START:: EMAIL INPUT -->
                <div class="input_wrapper verification_inputs_wrapper">
                  <input
                    ref="first_input"
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @keyup="toggleFocus"
                    v-model.trim="firstNumber"
                  />
                  <input
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @keyup="toggleFocus"
                    v-model.trim="secondNumber"
                  />
                  <input
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @keyup="toggleFocus"
                    v-model.trim="thirdNumber"
                  />
                  <input
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @keyup="toggleFocus"
                    v-model.trim="fourthNumber"
                  />
                </div>
                <!-- END:: EMAIL INPUT -->
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <!-- START:: BUTTON -->
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              متابعة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
            <!-- END:: BUTTON -->
          </div>
        </form>
      </div>
      <!-- ------------------------------===================================-------------------------- -->
      <div class="inputs custom_card" v-if="levelCode == 'password'">
        <div class="card-header">
          <div class="log-img">
            <img src="@/assets/images/icons/logo-white.png" />
          </div>
          <h4>تغيير كلمة المرور</h4>
          <p>يرجي إدخال كلمة المرور الجديده الخاصه بك</p>
        </div>
        <form
          class="form form-horizontal"
          @submit.prevent="validatePasswordForm"
        >
          <div class="form-body">
            <div class="row">
              <div class="col-12">
                <!-- START:: EMAIL INPUT -->
                <div class="input_wrapper animated_placeholder">
                  <button
                    type="button"
                    class="toggle_pass_btn"
                    @click="togglePasswordVisibility"
                  >
                    <i class="fal fa-eye open_eye"></i>
                    <i class="fal fa-eye-slash closed_eye"></i>
                  </button>
                  <input
                    type="password"
                    class="form-control"
                    id="login_pass"
                    v-model.trim="password"
                    @input="checkIfInputIsEmpty"
                    autocomplete="off"
                    autofill="off"
                    value=""
                  />
                  <label for="login_pass" class="form-label"
                    >كلمة المرور الجديدة</label
                  >
                </div>
                <div class="input_wrapper animated_placeholder">
                  <button
                    type="button"
                    class="toggle_pass_btn"
                    @click="togglePasswordVisibility"
                  >
                    <i class="fal fa-eye open_eye"></i>
                    <i class="fal fa-eye-slash closed_eye"></i>
                  </button>
                  <input
                    type="password"
                    class="form-control"
                    id="login_pass1"
                    v-model.trim="password_confirmation"
                    @input="checkIfInputIsEmpty"
                    autocomplete="off"
                    autofill="off"
                    value=""
                  />
                  <label for="login_pass1" class="form-label"
                    >تأكيد كلمة المرور الجديدة</label
                  >
                </div>
                <!-- END:: EMAIL INPUT -->
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <!-- START:: BUTTON -->
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تأكيد
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
            <!-- END:: BUTTON -->
          </div>
        </form>
      </div>
      <!-- Image Side -->
      <div class="image">
        <img src="@/assets/images/pages/login/white.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Loading
      btnIsLoading: false,
      email: null,
      code: null,
      password: null,
      password_confirmation: null,
      levelCode: "email",
      // START:: VERIFICATION INPUTS DATA
      firstNumber: null,
      secondNumber: null,
      thirdNumber: null,
      fourthNumber: null,
      // END:: VERIFICATION INPUTS DATA
      // START:: TIMER DATA
      timerCount: 60,
      // END:: TIMER DATA
    };
  },

  methods: {
    // START:: TOGGLE INPUTS FOCUS
    toggleFocus(e) {
      if (e.currentTarget.value.length == 1) {
        if (e.currentTarget.nextSibling) {
          e.currentTarget.blur();
          e.currentTarget.nextSibling.focus();
        }
      } else if (e.currentTarget.value.length == 0) {
        if (e.currentTarget.previousSibling) {
          e.currentTarget.blur();
          e.currentTarget.previousSibling.focus();
        }
      }
    },
    // END:: TOGGLE INPUTS FOCUS
    // START:: COUNTDOWN TIMER
    countDown() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          this.timerCount -= 1;
          this.countDown();
        }, 1000);
      }
    },
    // END:: COUNTDOWN TIMER
    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: TOGGLE PASSWORD VISIBILITY METHOD
    togglePasswordVisibility(e) {
      let passwordElement = e.currentTarget.parentElement.children[1];
      let passwordTogglerBtn = e.currentTarget;
      if (passwordElement.type == "password") {
        passwordElement.type = "text";
        passwordTogglerBtn.classList.add("password_is_visible");
      } else if (passwordElement.type == "text") {
        passwordElement.type = "password";
        passwordTogglerBtn.classList.remove("password_is_visible");
      }
    },
    // END:: TOGGLE PASSWORD VISIBILITY METHOD

    launch_resize_dispatch() {
      this.$store.dispatch("sideNav_module/onResize");
    },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    // START:: VALIDATE LOGIN FORM
    validateLoginForm() {
      this.btnIsLoading = true;
      if (!this.email) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرحي إدخال البريد الإلكتروني",
          position: "topRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.btnIsLoading = true;
        const data = new FormData();
        data.append("email", this.email);
        this.$axios
          .post("send_email", data)
          .then((res) => {
            this.btnIsLoading = false;
            this.levelCode = "code";
            this.countDown();
            this.$refs.first_input.focus();
          })
          .catch((err) => {
            this.btnIsLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "topRight",
            });
          });
      }
    },
    // END:: VALIDATE LOGIN FORM

    // START:: SUBMIT Code Form
    validateCodeForm() {
      this.btnIsLoading = true;
      if (!this.email) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إدخال كود التفعيل",
          position: "topRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.btnIsLoading = true;
        const data = new FormData();
        data.append("email", this.email);
        this.code =
          this.firstNumber +
          this.secondNumber +
          this.thirdNumber +
          this.fourthNumber;
        data.append("code", this.code);
        this.$axios
          .post("check_code", data)
          .then((res) => {
            this.btnIsLoading = false;
            this.levelCode = "password";
            this.$iziToast.success({
              timeout: 2000,
              message: "كود التفعيل صحيح",
              position: "topRight",
            });
          })
          .catch((err) => {
            this.btnIsLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "topRight",
            });
          });
      }
    },
    // END:: SUBMIT Code FORM

    // START:: SUBMIT Code Form
    validatePasswordForm() {
      this.btnIsLoading = true;
      if (!this.password) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إدخال كلمة المرور الجديدة",
          position: "topRight",
        });
        this.btnIsLoading = false;
        return;
      }
      if (!this.password_confirmation) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إدخال تأكيد كلمة المرور الجديدة",
          position: "topRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.btnIsLoading = true;
        const data = new FormData();
        data.append("email", this.email);
        data.append("code", this.code);
        data.append("password", this.password);
        data.append("password_confirmation", this.password_confirmation);
        this.$axios
          .post("reset_password", data)
          .then((res) => {
            this.$iziToast.success({
              timeout: 2000,
              message: "تم تغيير كلمة المرور بنجاح",
              position: "topRight",
            });
            this.$router.push("/login");
          })
          .catch((err) => {
            this.btnIsLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "topRight",
            });
          });
      }
    },
    // END:: SUBMIT Code FORM
  },

  mounted() {
    if (this.$store.getters.resizeEventExisit == true) {
      window.removeEventListener("resize", this.launch_resize_dispatch, true);
      this.$store.commit("changeRessizeEvent");
    }

    document.querySelector("body").style.padding = "0 !important";
  },
};
</script>

// ======== core Vue
import Vue from "vue";

// ======== Main app
import App from "./App.vue";

// ======== Router
import router from "./router";

// ======== Store
import store from "./store";

// ======== Mixins
import helpers from "./globalHelpers";
Vue.mixin(helpers);

// ======== language
store.dispatch("lang_module/handleLang"); // ==> Store (Vuex) <==
store.dispatch("theme_module/handleTheme"); // ==> Store (Vuex)  <==

// ========  Start Axios
import axios from "axios";

Vue.prototype.$axios = axios; // Glopal variable

// Global headers

axios.defaults.baseURL = "https://khb.crmsystems.aait-d.com/public/api"; // baseurl
axios.defaults.headers.common["cache-control"] = "no-cache";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Accept-language"] =
    store.getters["lang_module/lang"]; // ==> Store (Vuex) <==

// Set Token If Exists
// if (store.getters["auth_module/token"]) {
//   axios.defaults.headers.common["Authorization"] =
//     "bearer" + store.getters["auth_module/token"];
// }
// ========  End Axios

// ======== Bootstrap
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
Vue.use(BootstrapVue);

// ======== iziToast
import Loader from "./components/Loaders/loader";
import Notfound from "./components/Global/notFound";
import PrintHead from "./components/Global/printHead";
import iziToast from "izitoast";
// import VueHtml2pdf from 'vue-html2pdf'
import "izitoast/dist/css/iziToast.min.css";
import JsonExcel from "vue-json-excel";
Vue.prototype.$iziToast = iziToast; // Glopal variable
Vue.use(iziToast);
Vue.component("Loader", Loader);
Vue.component("Notfound", Notfound);
// Vue.component('VueHtml2pdf', VueHtml2pdf)
Vue.component("PrintHead", PrintHead);
Vue.component("downloadExcel", JsonExcel);
// ======== Moment
const moment = require("moment");
if (store.getters["lang_module/lang"] == "ar") {
    require("moment/locale/ar");
}
Vue.use(require("vue-moment"), {
    moment,
});

// ======== AOS
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

// ======== i18n
import i18n from "./i18n";

// ======= vuetify
import vuetify from "./plugins/vuetify";

// =============> Vue Slike Carousal
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
Vue.component("vue-slick", VueSlickCarousel);

// =============> Multiselect
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
Vue.component("multiselect", Multiselect);

// =============> ChatScroll
import VueChatScroll from "vue-chat-scroll";
Vue.use(VueChatScroll);

// ======== AudioRecorder
import AudioRecorder from "vue-audio-recorder";
Vue.use(AudioRecorder);

// ========  Main Style
import "./assets/scss/main.scss";

// =============> Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
    load: {
        // Add your API key here
        key: "AIzaSyAITrPfT5r_qmCm_8ekZyPmnebGo8o_r18",
        libraries: "places", // This is required if you use the Auto complete plug-in
    },
});

// ============> Ckeditor
import CKEditor from "ckeditor4-vue";
Vue.use(CKEditor);

// =============> Vue Particles
import VueParticles from "vue-particles";
Vue.use(VueParticles);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

// Import & Register Global Components
import BaseModel from "./components/ui/BaseModel.vue";
Vue.component("base-model", BaseModel);

import UplodeImage2 from "./components/uplods/UplodeImage2.vue";
Vue.component("uplode-image-2", UplodeImage2);

import UplodeImage from "./components/uplods/UplodeImage.vue";
Vue.component("uplode-image", UplodeImage);

import UplodeMultiple from "./components/uplods/UplodeMultiple.vue";
Vue.component("uplode-multiple", UplodeMultiple);

import Breadcrumb from "./components/Global/Breadcrumb.vue";
Vue.component("Breadcrumb", Breadcrumb);

import ChatComp from "./components/Global/ChatComp.vue";
Vue.component("chat-comp", ChatComp);

import ProfileCard from "./components/Global/ProfileCard.vue";
Vue.component("profile-card", ProfileCard);

import TableFilter from "./components/Global/TableFilter.vue";
Vue.component("table-filter", TableFilter);
// ======== Attatch and Mount

Vue.config.productionTip = false;

// refresh token

if (localStorage.getItem("Rehab_App_Token")) {
    axios
        .get("check_token", {
            headers: {
                Authorization: "bearer" + localStorage.getItem("Rehab_App_Token"),
            },
        })
        .then((res) => {
            localStorage.setItem("Rehab_App_Token", localStorage.getItem("Rehab_App_Token"));
            store.commit("auth_module/set_token", localStorage.getItem("Rehab_App_Token"));
            axios.defaults.headers.common["Authorization"] = "bearer" + localStorage.getItem("Rehab_App_Token");
            store.dispatch("get_notification");
            new Vue({
                router,
                store,
                i18n,
                vuetify,
                render: (h) => h(App),
            }).$mount("#app");
        })
        .catch((err) => {
            if (err.response.status == 401) {
                iziToast.error({
                    timeout: 2000,
                    message: "تسجيل خروج من السيستم",
                    position: "topRight",
                });
                setTimeout(() => {
                    store.dispatch("auth_module/logOut");
                }, 500);
            }
        });

} else {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: (h) => h(App),
    }).$mount("#app");
}
// end of refresh token
<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        type="english"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection @filterTable="searchTable"></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Notfound v-if="!searchItems" type="english"></Notfound>
      <!-- Start Statistics Card-->
      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>{{ table_length }}</span> <span>: Company Count</span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <!-- Start Main Section -->
        <main class="table-print main-table">
          <!-- start of  header of printSection  -->
          <PrintHead :title="'Companies'" :number="table_length"></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb"
            :headers="headers"
            :items="rows"
            :search="search"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            :items-per-page="rows.length"
            item-key="id"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- ====== Select row field ====== -->
            <!-- Select Image-->
            <template v-slot:[`item.image`]="{ item }">
              <img @click="show_model_1" class="image" :src="item.image" />
            </template>
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <!-- Select Image-->
            <template v-slot:[`item.commercial_image`]="{ item }">
              <img
                @click="show_model_1"
                class="image"
                :src="item.commercial_image"
              />
            </template>
            <!-- Select row field -->
            <template v-slot:[`item.status`]="{ item }">
              <v-chip color="#d7c100" v-if="item.status == 'pending'">
                Pending
              </v-chip>
              <v-chip color="green" v-if="item.status == 'confirmed'">
                Confirmed
              </v-chip>
              <v-chip color="red" v-if="item.status == 'refused'">
                Refused
              </v-chip>
            </template>
            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>
            <!-- Confirm-->
            <template v-slot:[`item.confirm`]="{ item }">
              <div class="_actions actions2" v-if="item.status == 'pending'">
                <button
                  type="button"
                  class="accept confirm_btn"
                  @click="acceptCompany(item.id)"
                >
                  <i class="far fa-check"></i>
                </button>
                <button
                  type="button"
                  class="reject confirm_btn"
                  @click="rejectCompany(item.id)"
                >
                  <i class="far fa-times"></i>
                </button>
              </div>
              <span v-if="item.status != 'pending'">-----</span>
            </template>
            <!-- Confirm-->
            <template v-slot:[`item.pdf_file`]="{ item }">
              <button
                type="button"
                @click="generateReport(item)"
                style="font-size: 20px"
              >
                <i class="fal fa-download"></i>
              </button>
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon>
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>
      <!-- End Pagination -->
      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </div>
    <!-- pdf file  -->
    <section class="pdf-print">
      <!-- start of  header of printSection  -->
      <div class="pdf-file text-left">
        <div class="text">
          <h3>Company Data</h3>
          <div class="sub">
            <div class="items">
              <p>Product</p>
              <p>{{ pdfContent.products }}</p>
            </div>
            <div class="items">
              <p>Name</p>
              <p>{{ pdfContent.fileName }}</p>
            </div>
            <div class="items">
              <p>BENEFICIARY ADDRESS</p>
              <p>{{ pdfContent.address }}</p>
            </div>
            <div class="items">
              <p>ACCOUNT</p>
              <p>{{ pdfContent.iban }}</p>
            </div>

            <div class="items">
              <p>SWIFT CODE</p>
              <p>{{ pdfContent.swift }}</p>
            </div>
            <div class="items">
              <p>BANK NAME</p>
              <p>{{ pdfContent.bankName }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/companies";
export default {
  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: "Home Page",
          disabled: false,
          href: "/",
        },
        {
          text: "All Compaines",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "الشركات",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Loding
      lodaing: false,
      table_length: null,
      // pdf
      pdfContent: {
        fileName: null,
        clientName: null,
        country: null,
        address: null,
        iban: null,
        bankName: null,
        swift: null,
        products: null,
      },
      // excel data
      excelData: {
        name: "companies",
        items: null,
        json_fields: {
          "Company Name": "name",
          Country: "country.name_ar",
          Address: "address",
          "IBAN NO or A/C NO": "account_number",
          "SWIFT CODE": "swift_number",
          "Bank Name": "bank.name",
          Product: "product_name",
          Status: {
            field: "status",
            callback: (value) => {
              if (value == "pending") {
                return "pending";
              } else if (value == "confirmed") {
                return "confirmed";
              } else if (value == "refused") {
                return "refused";
              } else {
                return "----";
              }
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        var userType = JSON.parse(this.$store.state.usertype) || null;
        if (
          userType.includes("superadmin") ||
          (userType.includes("companies") &&
            userType.includes("confirm_companies"))
        ) {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "Company Name",
              value: "name",
              align: "center",
              width: 120,
            },
            {
              text: "Country",
              value: "country.name_ar",
              align: "center",
              width: 100,
            },
            {
              text: "Address",
              value: "address",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 150,
            },
            {
              text: "IBAN NO or A/C NO",
              value: "account_number",
              align: "center",
              width: 200,
              sortable: false,
            },
            {
              text: "SWIFT CODE",
              value: "swift_number",
              align: "center",
              width: 100,
              sortable: false,
            },
            {
              text: "Bank Name",
              value: "bank.name",
              align: "center",
              width: 150,
            },
            {
              text: "Product",
              value: "product_name",
              align: "center",
              sortable: false,
              width: 100,
            },
            {
              text: "Status",
              value: "status",
              align: "center",
              sortable: false,
              width: 80,
            },
            {
              text: "PDF File",
              value: "pdf_file",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              sortable: false,
              width: 80,
            },
            {
              text: "Confirm",
              value: "confirm",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              sortable: false,
              width: 100,
            },
            {
              text: "Control",
              value: "actions",
              sortable: false,
              align: "center",
              width: 100,
              class: "showOnPrint",
              cellClass: "showOnPrint",
            },
          ];
        } else if (!userType.includes("confirm_companies")) {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "Company Name",
              value: "name",
              align: "center",
              width: 120,
            },
            {
              text: "Country",
              value: "country.name_ar",
              align: "center",
              width: 100,
            },
            {
              text: "Address",
              value: "address",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 150,
            },
            {
              text: "IBAN NO or A/C NO",
              value: "account_number",
              align: "center",
              width: 200,
              sortable: false,
            },
            {
              text: "SWIFT CODE",
              value: "swift_number",
              align: "center",
              width: 100,
              sortable: false,
            },
            {
              text: "Bank Name",
              value: "bank.name",
              align: "center",
              width: 150,
            },
            {
              text: "Product",
              value: "product_name",
              align: "center",
              sortable: false,
              width: 100,
            },
            {
              text: "Status",
              value: "status",
              align: "center",
              sortable: false,
              width: 80,
            },
            {
              text: "PDF File",
              value: "pdf_file",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              sortable: false,
              width: 80,
            },
            {
              text: "Control",
              value: "actions",
              sortable: false,
              align: "center",
              width: 100,
              class: "showOnPrint",
              cellClass: "showOnPrint",
            },
          ];
        } else {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "Company Name",
              value: "name",
              align: "center",
              width: 120,
            },
            {
              text: "Country",
              value: "country.name_ar",
              align: "center",
              width: 100,
            },
            {
              text: "Address",
              value: "address",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 150,
            },
            {
              text: "IBAN NO or A/C NO",
              value: "account_number",
              align: "center",
              width: 200,
              sortable: false,
            },
            {
              text: "SWIFT CODE",
              value: "swift_number",
              align: "center",
              width: 100,
              sortable: false,
            },
            {
              text: "Bank Name",
              value: "bank.name",
              align: "center",
              width: 150,
            },
            {
              text: "Product",
              value: "product_name",
              align: "center",
              sortable: false,
              width: 100,
            },
            {
              text: "Status",
              value: "status",
              align: "center",
              sortable: false,
              width: 80,
            },
            {
              text: "PDF File",
              value: "pdf_file",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              sortable: false,
              width: 80,
            },
            {
              text: "Confirm",
              value: "confirm",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              sortable: false,
              width: 100,
            },
          ];
        }
      }
    },
  },

  methods: {
    // generate pdf
    generateReport(companyData) {
      document.title = companyData.name;
      this.pdfContent.fileName = companyData.name;
      this.pdfContent.country = companyData.country.name_ar;
      this.pdfContent.address = companyData.address;
      this.pdfContent.iban = companyData.account_number;
      this.pdfContent.swift = companyData.swift_number;
      this.pdfContent.bankName = companyData.bank.name;
      this.pdfContent.products = companyData.product_name;
      setTimeout(() => {
        var iframePrint = document.querySelector(".iframePrint");
        var iframeHead = document.head.innerHTML;
        var printContents = document.querySelector(".pdf-print").innerHTML;
        iframePrint.contentWindow.document.write(iframeHead + printContents);
        setTimeout(() => {
          window.frames["printf"].print();
          iframePrint.contentWindow.document.close();
          document.title = "KHP-Group";
        }, 500);
      }, 500);
    },
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/addCompany" });
    },
    editItem(item) {
      this.$router.push({ path: "/editCompany/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/company/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.setRows();
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // ================ Set Rows
    setRows() {
      this.rows = [];
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/company",
        params: {
          keyword: this.searchItems ? this.searchItems.companyName : null,
          number: this.searchItems ? this.searchItems.bankNum : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.rows.forEach((ele) => {
            ele["product_name"] = ele.products[0].name;
          });
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },
    acceptCompany(id) {
      this.lodaing = true;
      this.$axios({
        method: "POST",
        url: "client/company/accept_company/" + id,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: "تأكيد بنجاح",
            position: "topRight",
          });
          this.setRows();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
    rejectCompany(id) {
      this.lodaing = true;
      this.$axios({
        method: "POST",
        url: "client/company/refuse_company/" + id,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: "تم الإلغاء بنجاح",
            position: "topRight",
          });
          this.setRows();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("companies") &&
      !userType.includes("confirm_companies")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

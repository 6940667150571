<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection @filterTable="searchTable"></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Loader v-if="lodaing" class="sub-loader"></Loader>
      <div v-else>
        <Notfound v-if="!searchItems"></Notfound>
        <div v-else>
          <!-- Start Statistics Card-->
          <ul class="all-state-count">
            <li>
              <div class="state-count">
                <span>عدد عملاء تنفيذ شغل الشركات:</span>
                <span>{{ table_length }}</span>
              </div>
            </li>
          </ul>
          <!-- End Statistics Card-->

          <!-- Start Main Section -->
          <main class="table-print main-table">
            <!-- start of  header of printSection  -->
            <PrintHead
              :title="'عملاء تنفيذ شغل الشركات'"
              :number="table_length"
            ></PrintHead>
            <!-- end of header of printSection  -->
            <div v-for="i in rows" :key="i.id">
              <div class="user-companies-tables" v-if="rows.length != 0">
                <div class="user-name">
                  <span> Client Name </span>
                  <p
                    @click="calculate(i.amount)"
                    style="cursor: pointer; color: red;"
                  >
                    {{ i.name }}
                  </p>
                </div>
                <v-data-table
                  class="thumb table_company table_paid"
                  :headers="headers"
                  :items="i.transfers"
                  :loading="lodaing"
                  :loading-text="$t('table.loadingData')"
                  v-model="selected"
                  :single-select="singleSelect"
                  item-key="id"
                  :items-per-page="i.transfers.length"
                  hide-default-footer
                >
                  <!-- Select no data State -->
                  <template v-slot:no-data>
                    {{ $t("table.noData") }}
                  </template>
                  <template v-slot:[`item.indexNum`]="{item}">
                    {{ item.indexNum }}
                  </template>
                  <template v-slot:[`item.reference`]="{item}">
                    <span v-if="item.reference"> {{ item.reference }} </span>
                    <span v-else> ----- </span>
                  </template>
                  <template v-slot:[`item.company.name`]="{item}">
                    <button
                      type="button"
                      style="width: 100%; height: 100%;"
                      @click="totalAmount(item, i)"
                      :class="{
                        checkItem: allCalcItems.includes(item),
                      }"
                    >
                      {{ item.company.name }}
                    </button>
                  </template>

                  <template v-slot:[`item.amount`]="{item}">
                    <button
                      type="button"
                      @click="totalAmount(item, i)"
                      style="width: 100%; height: 100%;"
                      :class="{
                        checkItem: allCalcItems.includes(item),
                      }"
                    >
                      {{ Number(item.amount).toLocaleString() }}
                      {{ item.currency }}
                    </button>
                  </template>
                  <template v-slot:[`item.company.country.name_ar`]="{item}">
                    <button
                      type="button"
                      @click="totalAmount(item, i)"
                      style="width: 100%; height: 100%;"
                      :class="{
                        checkItem: allCalcItems.includes(item),
                      }"
                    >
                      {{ item.company.country.name_ar }}
                    </button>
                  </template>
                  <template v-slot:[`item.company.swift_number`]="{item}">
                    <button
                      type="button"
                      @click="totalAmount(item, i)"
                      style="width: 100%; height: 100%;"
                      :class="{
                        checkItem: allCalcItems.includes(item),
                      }"
                    >
                      {{ item.company.swift_number }}
                    </button>
                  </template>
                  <template v-slot:[`item.created_at`]="{item}">
                    <button
                      type="button"
                      @click="totalAmount(item, i)"
                      style="width: 100%; height: 100%;"
                      :class="{
                        checkItem: allCalcItems.includes(item),
                      }"
                    >
                      {{ item.created_at }}
                    </button>
                  </template>
                  <!-- Status -->
                  <!-- Select row field -->
                  <template v-slot:[`item.status`]="{item}">
                    <v-chip
                      color="#d7c100"
                      class="mr-1"
                      v-if="item.status == 'pending'"
                    >
                      Pending
                    </v-chip>
                    <v-chip
                      color="green"
                      class="mr-1"
                      v-if="item.status == 'confirmed'"
                    >
                      Confirmed
                    </v-chip>
                    <v-chip
                      color="red"
                      class="mr-1"
                      v-if="item.status == 'refused'"
                    >
                      Refused
                    </v-chip>
                    <v-chip
                      color="green"
                      class="mr-1"
                      v-if="item.status == 'finished'"
                    >
                      Finished
                    </v-chip>
                  </template>
                  <!-- Confirm-->
                  <template v-slot:[`item.pdf_file`]="{item}">
                    <button
                      type="button"
                      @click="generateReport(item)"
                      style="font-size: 20px;"
                    >
                      <i class="fal fa-download"></i>
                    </button>
                  </template>
                  <!-- Select actions-->
                  <template v-slot:[`item.actions`]="{item}">
                    <div class="_actions">
                      <v-icon class="edit" small @click="editItem(item)">
                        fal fa-edit
                      </v-icon>
                      <v-icon class="delete" small @click="deleteItem(item)">
                        fal fa-trash
                      </v-icon>
                    </div>
                  </template>

                  <!-- ======================== Start Top Section ======================== -->
                  <template v-slot:top>
                    <!-- Delete dialog -->
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5 justify-center">{{
                          $t("table.deletedialog.areYouSure")
                        }}</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#1B5E20" @click="deleteItemConfirm"
                            >{{ $t("table.deletedialog.ok") }}
                            <span
                              class="btn_loader_model"
                              v-if="loadingBtnDialog"
                            ></span
                          ></v-btn>
                          <v-btn
                            color="#F44336"
                            @click="dialogDelete = false"
                            >{{ $t("table.deletedialog.cancel") }}</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <!-- ======================== End Top Section ======================== -->
                </v-data-table>
              </div>
              <div class="total-of-client" v-if="i.totalPerClient != 0">
                <p>
                  <bdi>
                    All Total Amounts for <span>" {{ i.name }} " :</span>
                    <span>
                      {{
                        parseFloat(
                          Number(i.totalPerClient).toFixed()
                        ).toLocaleString()
                      }}
                      {{ i.transfers[0].currency }}
                    </span>
                  </bdi>
                </p>
              </div>
              <transition name="slide-fade" mode="out-in">
                <div
                  class="calculater"
                  v-if="
                    calculater.amount ==
                    parseFloat(Number(i.amount).toFixed()).toLocaleString()
                  "
                >
                  <button class="close" @click="calculater.amount = null">
                    <i class="fas fa-times"></i>
                  </button>
                  <div class="row justify-content-between">
                    <div class="col-lg-12 py-0">
                      <div class="divider">
                        <h4>حاسبة العميل</h4>
                        <hr
                          role="separator"
                          aria-orientation="horizontal"
                          class="v-divider theme--light"
                        />
                      </div>
                    </div>
                    <div class="col-lg-3 py-0">
                      <div class="input_wrapper top_label">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          v-model="calculater.amount"
                        />
                        <label for="name_input" class="form-label"
                          >المبلغ
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-3 py-0">
                      <div class="input_wrapper top_label">
                        <input
                          type="number"
                          step="any"
                          class="form-control"
                          v-model="calculater.mulit"
                        />
                        <label for="name_input" class="form-label">ضرب </label>
                      </div>
                    </div>
                    <div class="col-lg-3 py-0">
                      <div class="input_wrapper top_label">
                        <input
                          type="text"
                          class="form-control"
                          v-model="calculater.divide"
                        />
                        <label for="name_input" class="form-label">قسمة </label>
                      </div>
                    </div>
                    <div class="col-lg-3 py-0">
                      <div class="input_wrapper top_label">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          v-model="calculaterAll"
                        />
                        <label for="name_input" class="form-label"
                          >الإجمالي
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </main>
          <!-- End Main Section -->
        </div>
      </div>
      <!-- End Pagination -->

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </div>
    <div class="total_amount">
      <p v-if="calcResult">
        All Total Amounts :
        <span :class="{redColor: calcResult < 0}">{{
          parseFloat(calcResult).toLocaleString()
        }}</span>
      </p>
    </div>
    <!-- pdf file  -->
    <section class="pdf-print">
      <div class="pdf-file text-left">
        <div class="text">
          <h3>Company job execution data</h3>
          <div class="sub">
            <div class="items">
              <p>Client Name</p>
              <p>{{ pdfContent.clientName }}</p>
            </div>
          </div>
          <div class="sub">
            <div class="items">
              <p>Enterprice Bank Account</p>
              <p>{{ pdfContent.enterprice }}</p>
            </div>
          </div>
          <div class="sub">
            <div class="items">
              <p>Product</p>
              <p>{{ pdfContent.products }}</p>
            </div>
            <div class="items">
              <p>Name</p>
              <p>{{ pdfContent.fileName }}</p>
            </div>
            <div class="items">
              <p>BENEFICIARY ADDRESS</p>
              <p>{{ pdfContent.address }}</p>
            </div>
            <div class="items">
              <p>A/C NO</p>
              <p>{{ pdfContent.iban }}</p>
            </div>
            <div class="items" v-if="pdfContent.refNum">
              <p>REFERENCE NO</p>
              <p>{{ pdfContent.refNum }}</p>
            </div>
            <div class="items">
              <p>SWIFT CODE</p>
              <p>{{ pdfContent.swift }}</p>
            </div>
            <div class="items">
              <p>BANK NAME</p>
              <p>{{ pdfContent.bankName }}</p>
            </div>
          </div>
          <div class="sub">
            <div class="items">
              <p>Amount</p>
              <p>{{ pdfContent.amount }}</p>
            </div>
            <div class="items">
              <p>Transfer Date</p>
              <p>{{ pdfContent.created_at }}</p>
            </div>
            <div class="items">
              <p>Transfer Status</p>
              <p v-if="pdfContent.status == 'pending'">Pending</p>
              <p v-if="pdfContent.status == 'confirmed'">Confirmed</p>
              <p v-if="pdfContent.status == 'refused'">Refused</p>
              <p v-if="pdfContent.status == 'finished'">Finished</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/companyAction";

export default {
  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "كل تنفيذات شغل الشركات",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "تنفيذ شغل الشركات",
          number: "1",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Filter
      show_filter: false,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // Custom Modal Data

      // ========== Loding
      lodaing: false,
      table_length: null,
      calculater: {
        amount: null,
        mulit: null,
        divide: 1,
      },
      allTransfares: [],
      allCalcItems: [],
      calcResult: null,
      // pdf
      pdfContent: {
        fileName: null,
        enterprice: null,
        clientName: null,
        refNum: null,
        country: null,
        address: null,
        iban: null,
        bankName: null,
        swift: null,
        products: null,
        amount: null,
        created_at: null,
        status: null,
      },
      // excel data
      excelData: {
        name: " تنفيذ شغل الشركات",
        items: null,
        json_fields: {
          "Client Name": {
            field: "name",
            callback: (value) => {
              var target = this.rows.find((ele) => {
                return ele.name == value;
              });
              var theLength = target.transfers.length;
              return (
                `<table><tbody><tr><td style='text-align: center; vertical-align: middle;' rowspan='${theLength}'>` +
                value +
                "</td></tr></tbody></table>"
              );
            },
          },
          "Total amount": {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    Number(val.amount).toLocaleString() +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal +=
                    "<tr><td>" +
                    Number(val.amount).toLocaleString() +
                    "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          "Company Name": {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    val.company.name +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal += "<tr><td>" + val.company.name + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          Swift: {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    val.company.swift_number +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal +=
                    "<tr><td>" + val.company.swift_number + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          Country: {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    val.company.country.name_ar +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal +=
                    "<tr><td>" + val.company.country.name_ar + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          "Created at": {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    val.created_at +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal += "<tr><td>" + val.created_at + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          Status: {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" + val.status + "</td></tr>" + "</tbody></table>";
                } else {
                  finalVal += "<tr><td>" + val.status + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
        },
      },
    };
  },

  computed: {
    calculaterAll() {
      var amount = Number(this.calculater.amount.replace(/\D/g, ""));
      const num = (amount * this.calculater.mulit) / this.calculater.divide;
      return parseFloat(num.toFixed()).toLocaleString();
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "indexNum",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: " Total Amount ",
            value: "amount",
            align: "center",
            sortable: false,
            width: 150,
          },
          {
            text: "Company Name",
            value: "company.name",
            align: "center",
            width: 180,
          },
          {
            text: "Country",
            value: "company.country.name_ar",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "Swift",
            value: "company.swift_number",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "Created at",
            value: "created_at",
            align: "center",
            width: 120,
          },
          {
            text: "Status",
            value: "status",
            align: "center",
            width: 100,
          },
          {
            text: "PDF File ",
            value: "pdf_file",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            sortable: false,
            width: 80,
          },
          {
            text: "Control",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 100,
          },
        ];
      }
    },
  },

  methods: {
    // generate pdf
    generateReport(companyData) {
      document.title = companyData.company.name;
      this.pdfContent.fileName = companyData.company.name;
      if (companyData.account != null) {
        this.pdfContent.enterprice = companyData.account.name;
      } else {
        this.pdfContent.enterprice = "لا يوجد";
      }
      this.pdfContent.clientName = companyData.customer.name;
      this.pdfContent.country = companyData.company.country.name_ar;
      this.pdfContent.address = companyData.company.address;
      this.pdfContent.iban = companyData.company.account_number;
      this.pdfContent.refNum = companyData.reference;
      this.pdfContent.swift = companyData.company.swift_number;
      this.pdfContent.bankName = companyData.company.bank.name;
      this.pdfContent.products = companyData.company.products[0].name;
      this.pdfContent.amount =
        Number(companyData.amount).toLocaleString() +
        " " +
        companyData.currency;
      this.pdfContent.created_at = companyData.created_at;
      this.pdfContent.status = companyData.status;
      setTimeout(() => {
        var iframePrint = document.querySelector(".iframePrint");
        var iframeHead = document.head.innerHTML;
        var printContents = document.querySelector(".pdf-print").innerHTML;
        iframePrint.contentWindow.document.write(iframeHead + printContents);
        setTimeout(() => {
          window.frames["printf"].print();
          iframePrint.contentWindow.document.close();
          document.title = "KHP-Group";
        }, 500);
      }, 500);
    },
    calculate(amount) {
      this.showCalculater = !this.showCalculater;
      var money = Number(amount);
      this.calculater.amount = parseFloat(money.toFixed()).toLocaleString();
    },
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.allCalcItems = [];
      this.calcResult = null;
      this.searchItems = items;
      this.setRows();
    },
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({path: "/debits/add"});
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({path: "/companyAction/edit/" + item.id});
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/company_transfer/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.allTransfares = [];
      this.$axios({
        method: "GET",
        url: "client/company_work",
        params: {
          customer: this.searchItems ? this.searchItems.username : null,
          company: this.searchItems ? this.searchItems.company : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.rows.forEach((ele) => {
            ele.transfers.forEach((mainEle) => {
              this.allTransfares.push(mainEle);
              this.allTransfares.forEach((subEle) => {
                if (mainEle.id == subEle.id) {
                  mainEle["indexNum"] =
                    this.allTransfares.indexOf(subEle, 0) + 1;
                }
              });
            });
            ele.totalPerClient = 0;
            ele.itemsToSum = [];
          });
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
    totalAmount(ele, mainItem) {
      const indexExpanded = this.allCalcItems.findIndex((i) => i.id === ele.id);
      //  all total
      if (indexExpanded > -1) {
        this.allCalcItems.splice(indexExpanded, 1);
        this.calcResult -= Number(ele.amount);
      } else {
        this.allCalcItems.push(ele);
        this.calcResult += Number(ele.amount);
      }
      // client total

      const found = mainItem.itemsToSum.find((el) => el.id == ele.id);
      if (found) {
        mainItem.itemsToSum = mainItem.itemsToSum.filter(
          (el) => el.id != found.id
        );
        let total = 0;
        mainItem.itemsToSum.map((el) => (total += +el.amount));
        mainItem.totalPerClient = total;
      } else {
        mainItem.itemsToSum.push(ele);
        let total = 0;
        mainItem.itemsToSum.map((el) => (total += +el.amount));
        mainItem.totalPerClient = total;
      }
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("company_actions")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="p-relative">
    <Loader v-if="loading"></Loader>
    <div class="homePage" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" />
      <!-- End Breadcrumb -->

      <!--Start Statistics Card-->
      <div class="row fadeIn">
        <div class="col-md-3" v-for="item in statisticsItems" :key="item.id">
          <stats-card
            :icon="item.icon"
            :title="item.title"
            :number="item.data"
            :color="current_theme == 'dark' ? '#282c87' : '#acc4d9'"
            :bgColor="'transparent'"
          ></stats-card>
        </div>
      </div>
      <!--End Statistics Card-->

      <!-- <div class="row fadeIn"> -->
      <!-- RADIAL BAR -->
      <!-- <div dir="ltr" class="col-12" v-if="lineChart.series">
        <div class="custom_card">
          <div class="card-header">
            <h5>رسم توضيحي</h5>
          </div>
          <apexchart
            type="line"
            height="350"
            :options="lineChart.options"
            :series="lineChart.series"
          ></apexchart>
        </div>
      </div>
    </div> -->
    </div>
  </div>
</template>

<script>
// import apexChatData from "../../components/Charts/apex-charts/apexChartData";
import StatsCard from "../../components/Charts/ChartComponent.vue";

export default {
  components: {
    StatsCard,
  },

  data() {
    return {
      // loader
      loading: true,
      // Apex_Chat_Data
      lineChart: {
        series: null,

        options: {
          chart: {
            height: 350,
            type: "line",
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#FF1654", "#247BA0"],
          stroke: {
            width: [4, 4],
          },
          plotOptions: {
            bar: {
              columnWidth: "20%",
            },
          },
          xaxis: {
            categories: null,
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#FF1654",
              },
              labels: {
                style: {
                  colors: "#FF1654",
                },
              },
              title: {
                text: "Current Week",
                style: {
                  color: "#FF1654",
                },
              },
            },
            {
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#247BA0",
              },
              labels: {
                style: {
                  colors: "#247BA0",
                },
              },
              title: {
                text: "Previous Week",
                style: {
                  color: "#247BA0",
                },
              },
            },
          ],
          tooltip: {
            shared: false,
            intersect: true,
            x: {
              show: false,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("mainHome"),
          disabled: false,
          href: "/",
        },
      ],

      statisticsItems: [],
    };
  },

  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
  },

  methods: {
    getData() {
      this.$axios({
        method: "GET",
        url: "home",
      })
        .then((res) => {
          let chartData = res.data.data;
          this.lineChart.series = [
            {
              name: "Current Week",
              data: chartData.income_days,
            },
            {
              name: "Previous Week",
              data: chartData.outcome_days,
            },
          ];
          this.lineChart.options.xaxis.categories = chartData.days;
          // this.statisticsItems = chartData.stats;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //Get Statitics
    getStatistics() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "statistic",
      }).then((res) => {
        this.statisticsItems = res.data.data.statistic;
        this.loading = false;
      });
    },
  },

  created() {
    // this.getData();
    this.getStatistics();
  },
};
</script>

<style lang="scss">
.custom_card.hideOverflow {
  direction: ltr;
}

.grid-widget-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.grid-widget {
  flex: 1;
  box-sizing: border-box;
  padding: 0;
  height: 100%;
  min-height: 350px;
  width: 100%;
  overflow: auto;
}

.chart-wrapper {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}
</style>

<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" :show_print="false" />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection @filterTable="searchTable"></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Loader v-if="lodaing" class="sub-loader"></Loader>
      <div v-else>
        <div>
          <!-- Start Statistics Card-->
          <ul class="all-state-count">
            <li>
              <div class="state-count">
                <span> محذوفات تنفيذ شغل الشركات:</span>
                <span>{{ table_length }}</span>
              </div>
            </li>
          </ul>
          <!-- End Statistics Card-->

          <!-- Start Main Section -->
          <main class="table-print main-table">
            <!-- start of  header of printSection  -->
            <PrintHead
              :title="'عملاء تنفيذ شغل الشركات'"
              :number="table_length"
            ></PrintHead>
            <!-- end of header of printSection  -->
            <div v-for="i in rows" :key="i.id">
              <div class="user-companies-tables" v-if="rows.length != 0">
                <div class="user-name">
                  <span> اسم العميل </span>
                  <p @click="calculate(i.amount)" style="cursor: pointer">
                    {{ i.name }}
                  </p>
                </div>
                <v-data-table
                  class="thumb table_company table_paid"
                  :headers="headers"
                  :items="i.transfers"
                  :loading="lodaing"
                  :loading-text="$t('table.loadingData')"
                  v-model="selected"
                  :single-select="singleSelect"
                  item-key="id"
                  :items-per-page="i.transfers.length"
                  hide-default-footer
                >
                  <!-- Select no data State -->
                  <template v-slot:no-data>
                    {{ $t("table.noData") }}
                  </template>
                  <template v-slot:[`item.index`]="{ index }">
                    {{ index + 1 }}
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    {{ Number(item.amount).toLocaleString() }}
                    {{ item.currency }}
                  </template>
                  <!-- Status -->
                  <!-- Select row field -->
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      color="#d7c100"
                      class="mr-1"
                      v-if="item.status == 'pending'"
                    >
                      مراجعة
                    </v-chip>
                    <v-chip
                      color="green"
                      class="mr-1"
                      v-if="item.status == 'confirmed'"
                    >
                      تأكيد
                    </v-chip>
                    <v-chip
                      color="red"
                      class="mr-1"
                      v-if="item.status == 'refused'"
                    >
                      مرفوض
                    </v-chip>
                    <v-chip
                      color="green"
                      class="mr-1"
                      v-if="item.status == 'finished'"
                    >
                      تم الإنتهاء
                    </v-chip>
                  </template>
                  <!-- Confirm-->
                  <template v-slot:[`item.pdf_file`]="{ item }">
                    <button
                      type="button"
                      @click="generateReport(item)"
                      style="font-size: 20px"
                    >
                      <i class="fal fa-download"></i>
                    </button>
                  </template>
                  <!-- Select actions-->
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="_actions">
                      <v-icon
                        class="restore"
                        color="success"
                        small
                        @click="restoreItem(item)"
                      >
                        fal fa-undo
                      </v-icon>
                    </div>
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <div class="_actions">
                      <v-icon class="delete" small @click="deleteItemEnd(item)">
                        fal fa-trash
                      </v-icon>
                    </div>
                  </template>

                  <!-- ======================== Start Top Section ======================== -->
                  <template v-slot:top>
                    <!-- Restore dialog -->
                    <v-dialog v-model="dialogRestore" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5 justify-center">
                          {{ $t("table.restoredialog.areYouSure") }}
                        </v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#1B5E20" @click="restoreItemConfirm">
                            {{ $t("table.restoredialog.ok")
                            }}<span
                              class="btn_loader_model"
                              v-if="loadingBtnDialog"
                            ></span>
                          </v-btn>
                          <v-btn color="#F44336" @click="dialogRestore = false">
                            {{ $t("table.restoredialog.cancel") }}
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- Add & Delete -->
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5 justify-center">
                          {{ $t("table.deletedialog.areYouSure") }}
                        </v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#1B5E20" @click="deleteItemEndConfirm">
                            {{ $t("table.deletedialog.ok") }}
                            <span
                              class="btn_loader_model"
                              v-if="loadingBtnDialog"
                            ></span>
                          </v-btn>
                          <v-btn color="#F44336" @click="dialogDelete = false">
                            {{ $t("table.deletedialog.cancel") }}
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <!-- ======================== End Top Section ======================== -->
                </v-data-table>
              </div>
              <transition name="slide-fade" mode="out-in">
                <div class="calculater" v-if="calculater.amount == i.amount">
                  <button class="close" @click="calculater.amount = null">
                    <i class="fas fa-times"></i>
                  </button>
                  <div class="row justify-content-between">
                    <div class="col-lg-12 py-0">
                      <div class="divider">
                        <h4>حاسبة العميل</h4>
                        <hr
                          role="separator"
                          aria-orientation="horizontal"
                          class="v-divider theme--light"
                        />
                      </div>
                    </div>
                    <div class="col-lg-3 py-0">
                      <div class="input_wrapper top_label">
                        <input
                          type="number"
                          step="any"
                          class="form-control"
                          disabled
                          v-model="calculater.amount"
                        />
                        <label for="name_input" class="form-label"
                          >المبلغ
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-3 py-0">
                      <div class="input_wrapper top_label">
                        <input
                          type="number"
                          step="any"
                          class="form-control"
                          v-model="calculater.mulit"
                        />
                        <label for="name_input" class="form-label">ضرب </label>
                      </div>
                    </div>
                    <div class="col-lg-3 py-0">
                      <div class="input_wrapper top_label">
                        <input
                          type="number"
                          step="any"
                          class="form-control"
                          v-model="calculater.divide"
                        />
                        <label for="name_input" class="form-label">قسمة </label>
                      </div>
                    </div>
                    <div class="col-lg-3 py-0">
                      <div class="input_wrapper top_label">
                        <input
                          type="number"
                          step="any"
                          class="form-control"
                          v-model="calculaterAll"
                        />
                        <label for="name_input" class="form-label"
                          >الإجمالي
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </main>
          <!-- End Main Section -->
        </div>
      </div>
      <!-- End Pagination -->

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </div>
    <!-- pdf file  -->
    <section class="pdf-print">
      <div class="pdf-file text-left">
        <div class="text">
          <h3>Company job execution data</h3>
          <div class="sub">
            <div class="items">
              <p>Client Name</p>
              <p>{{ pdfContent.clientName }}</p>
            </div>
          </div>
          <div class="sub">
            <div class="items">
              <p>Enterprice Bank Account</p>
              <p>{{ pdfContent.enterprice }}</p>
            </div>
          </div>
          <div class="sub">
            <div class="items">
              <p>Enterprice Bank Account</p>
              <p>{{ pdfContent.enterprice }}</p>
            </div>
          </div>
          <div class="sub">
            <div class="items">
              <p>Product</p>
              <p>{{ pdfContent.products }}</p>
            </div>
            <div class="items">
              <p>Name</p>
              <p>{{ pdfContent.fileName }}</p>
            </div>
            <div class="items">
              <p>BENEFICIARY ADDRESS</p>
              <p>{{ pdfContent.address }}</p>
            </div>
            <div class="items">
              <p>A/C NO</p>
              <p>{{ pdfContent.iban }}</p>
            </div>

            <div class="items">
              <p>SWIFT CODE</p>
              <p>{{ pdfContent.swift }}</p>
            </div>
            <div class="items">
              <p>BANK NAME</p>
              <p>{{ pdfContent.bankName }}</p>
            </div>
          </div>
          <div class="sub">
            <div class="items">
              <p>Amount</p>
              <p>{{ pdfContent.amount }}</p>
            </div>
            <div class="items">
              <p>Transfer Date</p>
              <p>{{ pdfContent.created_at }}</p>
            </div>
            <div class="items">
              <p>Transfer Status</p>
              <p v-if="pdfContent.status == 'pending'">Pending</p>
              <p v-if="pdfContent.status == 'confirmed'">Confirmed</p>
              <p v-if="pdfContent.status == 'refused'">Refused</p>
              <p v-if="pdfContent.status == 'finished'">Finished</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/deletedTable";
export default {
  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "محذوفات تنفيذات شغل الشركات",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "تنفيذ شغل الشركات",
          number: "1",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogRestore: false,
      itemtoRestore: null,
      dialogDelete: false,
      itemtoDelete: null,

      // ========== Filter
      show_filter: false,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // Custom Modal Data

      // ========== Loding
      lodaing: false,
      table_length: null,
      calculater: {
        amount: null,
        mulit: null,
        divide: 1,
      },
      // pdf
      pdfContent: {
        fileName: null,
        enterprice: null,
        clientName: null,
        country: null,
        address: null,
        iban: null,
        bankName: null,
        swift: null,
        products: null,
        amount: null,
        created_at: null,
        status: null,
      },
    };
  },

  computed: {
    calculaterAll() {
      const num =
        (this.calculater.amount * this.calculater.mulit) /
        this.calculater.divide;
      return parseFloat(num.toFixed()).toLocaleString();
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: " المبلغ الكلي ",
            value: "amount",
            align: "center",
            sortable: false,
            width: 150,
          },
          {
            text: "إسم الشركة",
            value: "company.name",
            align: "center",
            width: 200,
          },
          {
            text: "الدولة",
            value: "company.country.name_ar",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "تاريخ الإنشاء",
            value: "created_at",
            align: "center",
            width: 120,
          },
          {
            text: "الحالة",
            value: "status",
            align: "center",
            width: 100,
          },
          {
            text: "ملف pdf",
            value: "pdf_file",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            sortable: false,
            width: 80,
          },
          {
            text: "تاريخ الحذف",
            value: "deleted_at",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 100,
          },
          {
            text: "الحاذف",
            value: "deletedBy.name",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 100,
          },
          {
            text: "إرجاع",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "حذف",
            value: "delete",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
        ];
      }
    },
  },

  methods: {
    // generate pdf
    generateReport(companyData) {
      document.title = companyData.company.name;
      this.pdfContent.fileName = companyData.company.name;
      if (companyData.account != null) {
        this.pdfContent.enterprice = companyData.account.name;
      } else {
        this.pdfContent.enterprice = "لا يوجد";
      }
      this.pdfContent.clientName = companyData.customer.name;
      this.pdfContent.country = companyData.company.country.name_ar;
      this.pdfContent.address = companyData.company.address;
      this.pdfContent.iban = companyData.company.account_number;
      this.pdfContent.swift = companyData.company.swift_number;
      this.pdfContent.bankName = companyData.company.bank.name;
      this.pdfContent.products = companyData.company.products[0].name;
      this.pdfContent.amount =
        Number(companyData.amount).toLocaleString() +
        " " +
        companyData.currency;
      this.pdfContent.created_at = companyData.created_at;
      this.pdfContent.status = companyData.status;
      setTimeout(() => {
        var iframePrint = document.querySelector(".iframePrint");
        var iframeHead = document.head.innerHTML;
        var printContents = document.querySelector(".pdf-print").innerHTML;
        iframePrint.contentWindow.document.write(iframeHead + printContents);
        setTimeout(() => {
          window.frames["printf"].print();
          iframePrint.contentWindow.document.close();
          document.title = "KHP-Group";
        }, 500);
      }, 500);
    },
    calculate(amount) {
      this.showCalculater = !this.showCalculater;
      this.calculater.amount = amount;
    },
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/debits/add" });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({ path: "/companyAction/edit/" + item.id });
    },
    // delete end
    deleteItemEnd(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemEndConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "GET",
        url: `client/company_transfer/force_delete/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم مسح العنصر نهائياّ بنجاح",
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ===== Restore
    restoreItem(item) {
      this.dialogRestore = true;
      this.itemtoRestore = item;
    },
    restoreItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "GET",
        url: `client/company_transfer/restore_trash_item/${this.itemtoRestore.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogRestore = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم إرجاع العنصر بنجاح",
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogRestore = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/company_transfer/get_trash",
        params: {
          deleted_by_id: this.searchItems ? this.searchItems.username : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin")) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
